body {
  margin: 0;
  font-family: "Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.mod-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #2e3147;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  color: #fff;
  padding: 20px 30px;
  min-height: 300px;
  border-radius: 15px;
  max-width: 95vw;

  @media (max-width: 899px) {
    padding: 20px 15px;
  }
}

.mod-content h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
.mod-content .close-btn {
  position: absolute;
  top: 13px;
  right: 20px;
  color: #fff;
  @media (max-width: 899px) {
    right: 10px;
  }
}

html .notistack-Snackbar {
  top: -20px;
}

html .MuiChip-root {
  height: 20px;
  border-radius: 5px;
  margin-top: 5px;
}
html .MuiButtonBase-root {
  text-transform: none;
}

.alert-link {
  color: #fff;
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
}

html .MuiTypography-root {
  font-family: inherit;
}

html .MuiTooltip-tooltip {
  font-size: 14px;
}
